import React from "react";
import "font-awesome/scss/font-awesome.scss";
import Navigation from "../components/Navigation";
import config from "../../data/SiteConfig";
import "./index.scss";
import "./global.scss";

export default class MainLayout extends React.Component<{ title: string }> {
	render() {
		const { children } = this.props;
		return (
			<Navigation config={config} LocalTitle={this.props.title}>
				<div>{children}</div>
			</Navigation>
		);
	}
}
