import React, {
	FunctionComponent,
	useContext,
	useEffect,
	useState,
} from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import { IdentityUserContext } from "../components/identity/Identity";
import { authenticatedFetch } from "../components/utils/authenticatedFetch";
import { FEStripeData } from "../shared/types";
import { MyAccountPhoneNumber } from "../components/MyAccount/MyAccountPhoneNumber";
import { MyAccountCreditCards } from "../components/MyAccount/MyAccountCreditCards";
import { useLoginModal } from "../components/identity/LoginRegister/LoginRegisterModal";
import { Button } from "react-md";

const MyAccountLoggedIn: FunctionComponent<{}> = () => {
	// const {userService, } = useContext(IdentityUserContext);

	return (
		<div>
			<MyAccountPhoneNumber />
			<MyAccountCreditCards />
		</div>
	);
};

const MyAccountMain: FunctionComponent<{}> = () => {
	const { loggedIn } = useContext(IdentityUserContext);
	// const auth = useContext(AuthContext);
	const showLoginModal = useLoginModal();

	if (loggedIn) {
		return <MyAccountLoggedIn />;
	} else {
		return (
			<>
				<p>You are not logged in.</p>

				<Button raised primary onClick={() => showLoginModal()}>
					Login
				</Button>

				<Button raised primary onClick={() => showLoginModal("register")}>
					Register
				</Button>
			</>
		);
	}
};

const MyAccount: FunctionComponent<{}> = () => (
	<Layout title="My Account">
		<SEO title="Account" />
		<MyAccountMain />
	</Layout>
);

export default MyAccount;
