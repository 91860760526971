import React, {
	FunctionComponent,
	createContext,
	useState,
	ReactNode,
} from "react";
import { Snackbar } from "react-md";

export type AddToastType = (newToast: ReactNode) => void;

export const ToastContext = createContext<AddToastType>(() => null);

export const ToastProvider: FunctionComponent<{}> = ({ children }) => {
	const [toasts, setToasts] = useState<
		Array<{
			text: ReactNode;
			action?: ReactNode | { onClick?: () => void; label: ReactNode };
		}>
	>([]);
	const addToast: AddToastType = text =>
		setToasts([
			...toasts,
			{
				text,
			},
		]);
	const onDismiss = () => {
		const [, ...restToasts] = toasts;
		setToasts(restToasts);
		return restToasts;
	};
	return (
		<>
			<ToastContext.Provider value={addToast}>{children}</ToastContext.Provider>
			<Snackbar toasts={toasts} onDismiss={onDismiss} autohide />
		</>
	);
};
