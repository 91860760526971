import React, { FunctionComponent } from "react";
import { Identity } from "./identity/Identity";
import { CartProvider } from "./cart/CartProvider";
import { ToastProvider } from "./common/ToastProvider";

export const Providers: FunctionComponent<{}> = ({ children }) => (
	<ToastProvider>
		<Identity>
			<CartProvider>{children}</CartProvider>
		</Identity>
	</ToastProvider>
);
