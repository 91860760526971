import { IdentityUser } from "./Identity";
import { FEStripeData } from "../../shared/types";
import { authenticatedLambda } from "../utils";
import { AddToastType } from "../common/ToastProvider";
import GoTrue from "gotrue-js";
import { NetlifyUser } from "../../lambda/utils";

type TypeOfClassMethod<T, M extends keyof T> = T[M] extends (
	...args: any
) => any
	? T[M]
	: never;

export class UserService {
	constructor(
		private _userSetter: (user: IdentityUser) => void,
		private _showToast: AddToastType
	) {
		if (this._auth.currentUser()) {
			this.getStripeUser();
		}
	}

	private _auth = new GoTrue({
		APIUrl: `https://${process.env.GATSBY_NETLIFY_AUTH_DOMAIN}/.netlify/identity`,
		audience: "",
		setCookie: true,
	});

	register(userName: string, password: string, data: any) {
		return this._auth
			.signup(userName, password, data)
			.then(() => this.login(userName, password));
	}

	async login(userName: string, password: string) {
		const user = await this._auth.login(userName, password, true);
		this.getStripeUser();
		return user;
	}

	logout() {
		this._auth.currentUser().logout();
		this._userSetter(null);
	}

	forgotPassword(email: string) {
		return this._auth.requestPasswordRecovery(email);
	}

	async getStripeUser(): Promise<IdentityUser> {
		this._showToast("Getting your account info...");
		const stripeUser = await this.lambda("get-stripe-info");
		const { error } = stripeUser as any;
		if (error) {
			this._showToast(
				`There was a problem getting your account information. ${String(error)}`
			);
		} else {
			this._showToast("Successfuly loaded account info.");
		}
		return this._setUser(stripeUser);
	}

	async setPhoneNumber(phoneNumber: string) {
		try {
			const { user, ...result } = await this.lambda("set-stripe-phone-number", {
				phoneNumber,
			});
			if (result.status === "OK") {
				this._setUser(user);
			}
			return result;
		} catch (error) {
			console.error("Error in UserService.setPhoneNumber");
			console.error(error);
			return {
				status: "ERROR",
				msg: "There was a problem updating your phone number.",
			};
		}
	}

	async saveCreditCard(token: string, nickName: string) {
		try {
			const { user, ...result } = await this.lambda("save-credit-card", {
				token,
				nickName,
			});
			if (result.status === "OK") {
				this._setUser(user);
			}
			return result;
		} catch (error) {
			console.error("Error in UserService.saveCreditCard");
			console.error(error);
			return {
				status: "ERROR",
				msg: "There was a problem saving your credit card.",
			};
		}
	}

	async removeCreditCard(cardID: string) {
		try {
			const { user, ...result } = await this.lambda("remove-credit-card", {
				cardID,
			});
			if (result.status === "OK") {
				this._setUser(user);
			}
			return result;
		} catch (error) {
			console.error("Error in UserService.removeCreditCard");
			console.error(error);
			return {
				status: "ERROR",
				msg: "There was a problem removing your credit card.",
			};
		}
	}

	async lambda(path: string, payload?: any) {
		const jwt = await this._auth.currentUser().jwt();
		return authenticatedLambda(jwt, path, payload);
	}

	private _setUser(stripeUser: FEStripeData): IdentityUser {
		const user = mapUser(stripeUser);
		this._userSetter(user);
		return user;
	}
}

function mapUser(stripeUser: FEStripeData): IdentityUser {
	return {
		contact: {
			fullName: stripeUser.name,
			email: stripeUser.email,
			phone: stripeUser.phoneNumber,
		},
		savedCreditCards: stripeUser.savedCreditCards,
		id: stripeUser.email,
	};
}
