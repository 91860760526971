import React, {
	FunctionComponent,
	useState,
	useContext,
	useCallback,
} from "react";
import {
	CardElement,
	injectStripe,
	ReactStripeElements,
} from "react-stripe-elements";
import { DialogContainer, Button, TextField } from "react-md";
import { IdentityUserContext } from "../identity/Identity";
import { ToastContext } from "../common/ToastProvider";

const MAX_CARDS_ALLOWED = 5;

const WrappedSaveCreditCard: FunctionComponent<
	ReactStripeElements.InjectedStripeProps
> = ({ stripe, children }) => {
	const { userService, savedCreditCards } = useContext(IdentityUserContext);
	const toast = useContext(ToastContext);
	const [showAddCardDialog, setShowAddCardDialog] = useState(false);
	const [nickName, setNickName] = useState("");
	const [stripeElemComplete, setStripeElemComplete] = useState(false);

	const showDialog = e => {
		e.preventDefault();
		setShowAddCardDialog(true);
	};

	const submitCard = useCallback(
		async e => {
			e.preventDefault();
			const { token } = await stripe.createToken({});
			const result = await userService.current.saveCreditCard(
				token.id,
				nickName
			);
			setShowAddCardDialog(false);
			setNickName("");
			toast(result.msg);
		},
		[stripe, nickName]
	);
	if (savedCreditCards.length >= MAX_CARDS_ALLOWED) {
		return <></>;
	}

	return (
		<div>
			<div onClick={showDialog}>
				{children ? (
					children
				) : (
					<Button raised primary>
						Add Credit Card
					</Button>
				)}
			</div>
			<DialogContainer
				visible={showAddCardDialog}
				id="addCCDialog"
				onHide={() => setShowAddCardDialog(false)}
				onShow={() => setStripeElemComplete(false)}
				title="Save a Credit Card to your account"
				focusOnMount={false}
				actions={[
					<Button
						primary
						raised
						disabled={!stripeElemComplete}
						onClick={submitCard}
					>
						OK
					</Button>,
				]}
			>
				<CardElement
					onChange={({ complete }) => setStripeElemComplete(complete)}
				/>
				<TextField
					name="NickName"
					id="addCCNick"
					label="Nick Name"
					value={nickName}
					onChange={value => setNickName(value as string)}
				/>
			</DialogContainer>
		</div>
	);
};

export const SaveCreditCard = injectStripe(WrappedSaveCreditCard);
