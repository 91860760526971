import React, {
	FunctionComponent,
	useState,
	useContext,
	Dispatch,
	SetStateAction,
} from "react";
import {
	CardElement,
	injectStripe,
	ReactStripeElements,
} from "react-stripe-elements";
import { CartContext } from "../cart/CartProvider";
import Stripe from "stripe";
import { Card, CardText, TextField, Button, CardActions } from "react-md";
import { ServiceFee } from "./ServiceFee";
import { AsyncStatus } from "../utils";

interface WrappedCheckoutFormProps
	extends ReactStripeElements.InjectedStripeProps {
	setStripeOrder: Dispatch<SetStateAction<Stripe.orders.IOrder>>;
	setDialogText: Dispatch<SetStateAction<string>>;
}

export const WrappedCheckoutForm: FunctionComponent<
	WrappedCheckoutFormProps
> = ({ stripe, setStripeOrder, setDialogText }) => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [stripeElemComplete, setStripeElemComplete] = useState(false);
	const [serviceFeeAck, setServiceFeeAck] = useState(false);

	const [asyncStatus, setAsyncStatus] = useState<AsyncStatus>(
		AsyncStatus.EMPTY
	);
	const { items: cartItems, cart } = useContext(CartContext);

	const emailIsValid = email.match(/^\S+@\S+\.\S+/);
	const formIsValid =
		name.length > 0 && emailIsValid && stripeElemComplete && serviceFeeAck;
	const isLoading = asyncStatus === AsyncStatus.LOADING;

	async function onFormSubmit(e) {
		e.preventDefault();
		if (!formIsValid) {
			setDialogText("Please make sure to fill in all the fields.");
			return;
		}
		const cartData = cartItems.map(({ item, quantity }) => ({
			skuNo: item.id,
			quantity,
		}));
		if (asyncStatus !== AsyncStatus.LOADING) {
			setAsyncStatus(AsyncStatus.LOADING);
			try {
				const { token } = await stripe.createToken({
					name,
				});
				const result: {
					status: "OK" | "ERROR";
					msg: string;
					order: Stripe.orders.IOrder;
					errorType?: string;
					stripeData?: {
						message: string;
						type: string;
					};
				} = await fetch("/.netlify/functions/guest-checkout", {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ name, email, token, cartData }),
				}).then(response => response.json());
				if (result.status === "OK") {
					setStripeOrder(result.order);
					setAsyncStatus(AsyncStatus.LOADED);
					cart.reset();
				} else {
					setAsyncStatus(AsyncStatus.ERROR);
					if (result.stripeData && result.stripeData.message) {
						setDialogText(result.stripeData.message);
					}
				}
			} catch (error) {
				setAsyncStatus(AsyncStatus.ERROR);
			}
		}
	}
	return (
		<Card>
			<form onSubmit={e => onFormSubmit(e)}>
				<CardText>
					<>
						<TextField
							required
							name="Name"
							id="checkoutFormName"
							label="Your name"
							errorText="Please enter your name."
							value={name}
							onChange={value => setName(value as string)}
						/>
						<TextField
							type="email"
							name="email"
							id="checkoutFormEmail"
							required
							label="Email"
							errorText="Please enter your email address."
							value={email}
							onChange={value => setEmail(value as string)}
						/>
						<CardElement
							onChange={({ complete }) => setStripeElemComplete(complete)}
						/>
					</>
				</CardText>
				<CardText>
					<ServiceFee
						checked={serviceFeeAck}
						subtotal={cartItems.reduce<number>(
							(accum, { item, quantity }) => accum + item.price * quantity,
							0
						)}
						onChange={checked => setServiceFeeAck(checked)}
					/>
				</CardText>
				<CardActions>
					<Button
						raised
						primary
						type="submit"
						disabled={!formIsValid || isLoading}
					>
						{isLoading ? "Submitting...." : "Checkout"}
					</Button>
				</CardActions>
			</form>
		</Card>
	);
};

export const GuestCheckoutForm = injectStripe(WrappedCheckoutForm);
