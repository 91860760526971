import React, { FunctionComponent, useState, useContext } from "react";
import { Link } from "gatsby";

import Layout from "../layout";
import SEO from "../components/seo";
import { CartContext } from "../components/cart/CartProvider";
import { priceFormatter, Price } from "../components/Price";
import { StripeProvider, Elements } from "react-stripe-elements";
import { useStripe } from "../hooks/useStripe";
import { GuestCheckoutForm } from "../components/CheckoutForm/GuestCheckoutForm";
import {
	Grid,
	Cell,
	Card,
	CardTitle,
	Button,
	CardActions,
	CardText,
	DialogContainer,
} from "react-md";
import { DelayedRedirect } from "../components/common/DelayedRedirect";
import Stripe from "stripe";
import { IdentityUserContext } from "../components/identity/Identity";
import { MemberCheckoutForm } from "../components/CheckoutForm/MemberCheckoutForm";

const CartPageTemplate: FunctionComponent<{}> = () => {
	const stripe = useStripe();
	const [dialogText, setDialogText] = useState("");
	const identityContext = useContext(IdentityUserContext);
	const { items, cart } = useContext(CartContext);
	const showCheckout = items.length > 0;
	const [stripeOrder, setStripeOrder] = useState<Stripe.orders.IOrder | null>(
		null
	);
	const dialogActions = [
		<Button raised primary onClick={() => setDialogText("")}>
			OK
		</Button>,
	];

	if (stripeOrder) {
		return (
			<div>
				<Card>
					<CardTitle title="Thank you for your payment" />
					<CardText>
						<ul>
							{stripeOrder.items
								.filter(({ amount }) => amount > 0)
								.map(({ description, amount }, i) => (
									<li key={i}>
										<p>{description}</p>
										<p>
											<Price value={amount} />
										</p>
									</li>
								))}
						</ul>
						<p>
							Total: <Price value={stripeOrder.amount} />
						</p>
					</CardText>
				</Card>
			</div>
		);
	}

	return (
		<div>
			<>
				{items.length > 0 ? (
					<>
						<Grid>
							{items.map(({ item, quantity }) => (
								<Cell key={item.id}>
									<Card>
										<CardTitle
											title={item.product.name}
											subtitle={priceFormatter(item.price)}
										/>
										<CardText>
											<p>
												Price: <Price value={item.price} />
											</p>
											<p>Quantity: {quantity}</p>
											<p>
												SubTotal: <Price value={item.price * quantity} />
											</p>
										</CardText>
										<CardActions>
											<Button
												raised
												secondary
												onClick={() => cart.remove(item)}
											>
												Remove
											</Button>
										</CardActions>
									</Card>
								</Cell>
							))}
						</Grid>
					</>
				) : (
					<Grid>
						<Cell>
							<Card>
								<CardTitle title="OOPS." />
								<CardText>
									<p>There is nothing in your cart.</p>
									<p>
										Redirecting you to the{" "}
										<Link to="/payments">Payments Page</Link>
										<DelayedRedirect
											to="/payments"
											from="/cart"
											noThrow
											delay={1000}
										/>
									</p>
								</CardText>
							</Card>
						</Cell>
					</Grid>
				)}
			</>
			<>
				{showCheckout && (
					<StripeProvider stripe={stripe}>
						<Grid>
							<Cell>
								{identityContext && identityContext.loggedIn ? (
									<MemberCheckoutForm
										setStripeOrder={setStripeOrder}
										setDialogText={setDialogText}
									/>
								) : (
									<Elements>
										<GuestCheckoutForm
											setStripeOrder={setStripeOrder}
											setDialogText={setDialogText}
										/>
									</Elements>
								)}
							</Cell>
						</Grid>
					</StripeProvider>
				)}
			</>
			<DialogContainer
				visible={dialogText.length > 0}
				id="checkout-messages-dialog"
				onHide={() => setDialogText("")}
				title="Alert"
				actions={dialogActions}
			>
				<p>{dialogText}</p>
			</DialogContainer>
		</div>
	);
};

const CartPage: FunctionComponent<{}> = () => {
	return (
		<Layout title="Cart">
			<SEO title="Cart" />
			<CartPageTemplate />
		</Layout>
	);
};

export default CartPage;
