import React, { FunctionComponent, useContext } from "react";
import FontIcon from "react-md/lib/FontIcons";
import { Link } from "gatsby";
import { IdentityUserContext } from "../identity/Identity";
import { LinkButton } from "../common/SimpleButtons";
import { useLoginModal } from "../identity/LoginRegister/LoginRegisterModal";

const NavLoginButton: FunctionComponent<{}> = () => {
	const { loggedIn, userService } = useContext(IdentityUserContext);
	const showLoginModal = useLoginModal();
	return (
		<>
			{loggedIn ? (
				<LinkButton onClick={() => userService.current.logout()}>Logout</LinkButton>
			) : (
				<LinkButton onClick={() => showLoginModal()}>Login / Signup</LinkButton>
			)}
		</>
	);
};

function GetNavList(config) {
	const NavList = [
		{
			primaryText: "Home",
			leftIcon: <FontIcon>home</FontIcon>,
			component: Link,
			to: "/",
		},
		{
			divider: true,
		},
		{
			primaryText: "Payments",
			leftIcon: <FontIcon>store</FontIcon>,
			component: Link,
			to: "/payments",
		},
		{
			divider: true,
		},
		{
			primaryText: "Cart",
			leftIcon: <FontIcon>shopping_cart</FontIcon>,
			component: Link,
			to: "/cart",
		},
		{
			divider: true,
		},
		{
			primaryText: "Text Messages",
			leftIcon: <FontIcon>message</FontIcon>,
			component: Link,
			to: "/alerts",
		},
		{
			divider: true,
		},
		{
			primaryText: "My Account",
			leftIcon: <FontIcon>account_box</FontIcon>,
			component: Link,
			to: "/my-account",
		},

		{
			divider: true,
		},
		{
			primaryText: "FAQs",
			leftIcon: <FontIcon>help</FontIcon>,
			component: Link,
			to: "/faqs",
		},

		{
			divider: true,
		},
		{
			leftIcon: <FontIcon>account_box</FontIcon>,
			primaryText: <NavLoginButton />,
		},
	];

	/*if (config.userLinks) {
		config.userLinks.forEach(link => {
			NavList.push({
				primaryText: link.label,
				leftIcon: <FontIcon forceSize iconClassName={link.iconClassName} />,
				component: "a",
				href: link.url,
			});
		});
	}*/

	return NavList;
}
export default GetNavList;
