import React, {
	FunctionComponent,
	createContext,
	useEffect,
	useState,
	useRef,
	MutableRefObject,
	useContext,
} from "react";
import { SavedCC } from "../../shared/types";
import { UserService } from "./UserService";
import { ToastContext } from "../common/ToastProvider";
import { LoginRegisterModalProvider } from "./LoginRegister/LoginRegisterModal";

export interface IdentityUser {
	contact: {
		fullName: string;
		email: string;
		phone?: string;
	};
	savedCreditCards: SavedCC[];
	id: string;
}

type IdentityUserType = IdentityUser & {
	userService: MutableRefObject<UserService>;
	loggedIn: boolean;
};

export const IdentityUserContext = createContext<IdentityUserType>(null);

function useIdentity(): IdentityUserType {
	const [identityUser, setIdentityUser] = useState<IdentityUser>(null);
	const userService = useRef<UserService>(null);
	const toast = useContext(ToastContext);
	useEffect(() => {
		userService.current = new UserService(setIdentityUser, toast);
	}, []);
	return {
		...identityUser,
		userService,
		loggedIn: Boolean(identityUser && identityUser.id),
	};
}

export const Identity: FunctionComponent<{}> = ({ children }) => {
	const identityUser = useIdentity();
	return (
		<IdentityUserContext.Provider value={identityUser}>
			<LoginRegisterModalProvider>{children}</LoginRegisterModalProvider>
		</IdentityUserContext.Provider>
	);
};
