import React, {
	FunctionComponent,
	useState,
	createContext,
	useCallback,
	useContext,
} from "react";
import { DialogContainer } from "react-md";
import { Login } from "./Login";
import { Register } from "./Register";
import { ForgotPassword } from "./ForgotPassword";

export type LoginRegisterViewType = "login" | "register" | "forgot" | "";

export const LoginRegisterContext = createContext<
	(state?: LoginRegisterViewType) => void
>(() => {});

export function useLoginModal() {
	return useContext(LoginRegisterContext);
}

export const LoginRegisterModalProvider: FunctionComponent = ({ children }) => {
	const [viewType, setViewType] = useState<LoginRegisterViewType>("");
	const closeDialog = useCallback(() => setViewType(""), []);
	return (
		<>
			<LoginRegisterContext.Provider
				value={(viewType = "login") => setViewType(viewType)}
			>
				<DialogContainer
					id="login_modal"
					visible={viewType === "login"}
					onHide={closeDialog}
					title="Log In"
				>
					<Login closeDialog={closeDialog} />
				</DialogContainer>
				<DialogContainer
					id="register_dialog"
					visible={viewType === "register"}
					onHide={closeDialog}
					title="Register"
				>
					<Register closeDialog={closeDialog} />
				</DialogContainer>
				<DialogContainer
					id="forgot_password_dialog"
					visible={viewType === "forgot"}
					onHide={closeDialog}
					title="Forgot Password"
				>
					<ForgotPassword closeDialog={closeDialog} />
				</DialogContainer>
				{children}
			</LoginRegisterContext.Provider>
		</>
	);
};
