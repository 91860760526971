import React, { FunctionComponent, useState, useContext } from "react";
import { DialogContainer } from "react-md";
import { ToastContext } from "./ToastProvider";

interface ActionResponse {
	status: "OK" | "ERROR";
	msg: string;
	[k: string]: any;
}

interface ConfirmedActionProps {
	action: () => Promise<ActionResponse>;
	actionText: string;
}

export const ConfirmedAction: FunctionComponent<ConfirmedActionProps> = ({
	action,
	actionText,
	children,
}) => {
	const [dialogVisible, setDialogVisible] = useState(false);
	const toast = useContext(ToastContext);
	const hideDialog = () => setDialogVisible(false);

	const runAction = async () => {
		const { status, msg } = await action();
		toast(msg);
		if (status === "OK") {
			hideDialog();
		}
	};
	return (
		<>
			<div onClick={() => setDialogVisible(true)}>{children}</div>
			<DialogContainer
				visible={dialogVisible}
				onHide={hideDialog}
				id="ConfirmedActionDialog"
				actions={[
					{ secondary: true, children: "Cancel", onClick: hideDialog },
					{ primary: true, children: "Yes", onClick: runAction },
				]}
			>
				{actionText}
			</DialogContainer>
		</>
	);
};
