import React, { FunctionComponent, useState, useContext } from "react";
import {
	DialogContainer,
	Button,
	Card,
	CardTitle,
	CardText,
	ExpansionList,
	ExpansionPanel,
} from "react-md";
import { SaveCreditCard } from "./SaveCreditCard";
import { Elements, StripeProvider } from "react-stripe-elements";
import { useStripe } from "../../hooks/useStripe";
import { IdentityUserContext } from "../identity/Identity";
import { UserService } from "../identity/UserService";
import { RemoveCC } from "./RemoveCC";

export const MyAccountCreditCards: FunctionComponent<{}> = ({}) => {
	const stripe = useStripe();
	const { savedCreditCards } = useContext(IdentityUserContext);
	return (
		<Card>
			<CardTitle title="Saved Credit Cards" />
			<CardText>
				{savedCreditCards.length > 0 ? (
					<ExpansionList>
						{savedCreditCards.map(
							({ id, nickName, last4, isDefault, cardText }) => (
								<ExpansionPanel
									key={id}
									label={cardText}
									secondaryLabel={isDefault ? "Main" : ""}
									footer={
										<footer className="md-dialog-footer md-dialog-footer--inline md-divider-border md-divider-border--top">
											<RemoveCC cardID={id} />
										</footer>
									}
								>
									<p>...{last4}</p>
									<p>is Default: {isDefault.toString()}</p>
								</ExpansionPanel>
							)
						)}
					</ExpansionList>
				) : (
					<p>You don't have any credit cards saved.</p>
				)}
			</CardText>

			<CardText>
				<StripeProvider stripe={stripe}>
					<Elements>
						<SaveCreditCard />
					</Elements>
				</StripeProvider>
			</CardText>
		</Card>
	);
};
