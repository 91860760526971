import React, {
	FunctionComponent,
	useContext,
	useState,
	useEffect,
} from "react";
import { IdentityUserContext } from "../identity/Identity";
import {
	Card,
	CardTitle,
	CardText,
	CardActions,
	Button,
	DialogContainer,
	TextField,
} from "react-md";
import { AsyncStatus } from "../utils";

export const MyAccountPhoneNumber: FunctionComponent<{}> = () => {
	const { userService, ...user } = useContext(IdentityUserContext);
	const hasPhoneNumber = Boolean(user.contact && user.contact.phone);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState<string>(null);
	const [loadingStatus, setLoadingStatus] = useState<AsyncStatus>(
		AsyncStatus.EMPTY
	);

	useEffect(() => {
		setPhoneNumber(
			user.contact && user.contact.phone ? user.contact.phone : ""
		);
		if (loadingStatus !== AsyncStatus.LOADING) {
			setLoadingStatus(AsyncStatus.EMPTY);
		}
	}, [user.contact && user.contact.phone]);

	async function updatePhoneNumber(phoneNumber: string) {
		setLoadingStatus(AsyncStatus.LOADING);
		const { status, msg } = await userService.current.setPhoneNumber(
			phoneNumber
		);
		if (status === "OK") {
			setEditModalOpen(false);
		}
	}

	return (
		<>
			<Card>
				<CardTitle title="Phone Number" />
				<CardText>
					{hasPhoneNumber ? (
						<p>{user.contact.phone}</p>
					) : (
						<p>We do not have a phone number on file for you.</p>
					)}
					<CardActions>
						<Button secondary raised onClick={() => setEditModalOpen(true)}>
							{hasPhoneNumber ? "Update phone number" : "Enter Phone Number"}
						</Button>
					</CardActions>
				</CardText>
			</Card>
			<DialogContainer
				visible={editModalOpen}
				id="update_user_phone_modal"
				title="Update Phone Number"
				onShow={() => setLoadingStatus(AsyncStatus.EMPTY)}
				onHide={() => setEditModalOpen(false)}
				actions={[
					<Button
						secondary
						raised
						onClick={() => setEditModalOpen(false)}
						disabled={loadingStatus === AsyncStatus.LOADING}
					>
						Cancel
					</Button>,
					<Button
						primary
						raised
						onClick={() => updatePhoneNumber(phoneNumber)}
						disabled={loadingStatus === AsyncStatus.LOADING}
					>
						Save
					</Button>,
				]}
			>
				<TextField
					id="update_user_phone_field"
					value={phoneNumber}
					onChange={value => setPhoneNumber(String(value))}
				/>
			</DialogContainer>
		</>
	);
};
