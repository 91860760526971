import React, { FunctionComponent, useContext } from "react";
import { IdentityUserContext } from "../identity/Identity";
import { ToastContext } from "../common/ToastProvider";
import { ConfirmedAction } from "../common/ConfirmedAction";
import { Button } from "react-md";
export const RemoveCC: FunctionComponent<{
	cardID: string;
}> = ({ cardID }) => {
	const { userService } = useContext(IdentityUserContext);
	return (
		<ConfirmedAction
			action={() => userService.current.removeCreditCard(cardID)}
			actionText="Are you sure you want to remove this Credit Card?"
		>
			<Button secondary flat iconChildren="delete">
				remove
			</Button>
		</ConfirmedAction>
	);
};
