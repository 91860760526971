import React, { FunctionComponent, useContext } from "react";
import { IdentityUserContext } from "./identity/Identity";
import { Button } from "react-md";
import { useLoginModal } from "./identity/LoginRegister/LoginRegisterModal";

export const LoginButton: FunctionComponent<{}> = () => {
	const identityUser = useContext(IdentityUserContext);
	const showLoginModal = useLoginModal();

	return (
		<>
			{identityUser && identityUser.loggedIn ? (
				<Button
					flat
					primary
					onClick={() => identityUser.userService.current.logout()}
				>
					Logout
				</Button>
			) : (
				<Button flat primary onClick={() => showLoginModal()}>
					Login / Signup
				</Button>
			)}
		</>
	);
};
