import React, {
	FunctionComponent,
	useState,
	useContext,
	Dispatch,
	useEffect,
	useMemo,
	SetStateAction,
} from "react";
import { CartContext } from "../cart/CartProvider";
import Stripe from "stripe";
import {
	Card,
	CardText,
	Button,
	CardActions,
	IconSeparator,
	FontIcon,
	ListItem,
	SelectField,
} from "react-md";
import { ServiceFee } from "./ServiceFee";
import { AsyncStatus, authenticatedLambda } from "../utils";
import { IdentityUserContext } from "../identity/Identity";
import { SavedCC } from "../../shared/types";
import { SaveCreditCard } from "../MyAccount/SaveCreditCard";
import { Elements } from "react-stripe-elements";
import { LinkButton } from "../common/SimpleButtons";

interface MemberCheckoutFormProps {
	setStripeOrder: Dispatch<SetStateAction<Stripe.orders.IOrder>>;
	setDialogText: Dispatch<SetStateAction<string>>;
}

const getNick = ({ nickName, brand, last4 }: SavedCC) =>
	nickName || `${brand} card ending in ${last4}`;

export const MemberCheckoutForm: FunctionComponent<MemberCheckoutFormProps> = ({
	setStripeOrder,
	setDialogText,
}) => {
	const { id: userID, savedCreditCards } = useContext(IdentityUserContext);
	const [
		selSavedPaymentMethod,
		setSelSavedPaymentMethod,
	] = useState<SavedCC | null>(null);

	useEffect(() => {
		setSelSavedPaymentMethod(
			savedCreditCards
				? savedCreditCards.find(({ isDefault }) => isDefault)
				: null
		);
	}, [userID, savedCreditCards]);

	const [serviceFeeAck, setServiceFeeAck] = useState(false);

	const [asyncStatus, setAsyncStatus] = useState<AsyncStatus>(
		AsyncStatus.EMPTY
	);
	const { items: cartItems, cart } = useContext(CartContext);

	const formIsValid = selSavedPaymentMethod && serviceFeeAck;
	const isLoading = asyncStatus === AsyncStatus.LOADING;

	const savedCCSelections = useMemo(
		() =>
			savedCreditCards
				? savedCreditCards.map(savedCC => (
						<ListItem
							key={savedCC.id}
							primaryText={getNick(savedCC)}
							onClick={() => setSelSavedPaymentMethod(savedCC)}
						/>
				  ))
				: [],
		[savedCreditCards && savedCreditCards.length]
	);

	async function onFormSubmit(e) {
		e.preventDefault();
		if (!formIsValid) {
			setDialogText(
				"Please make sure to select a payment method, and acknowlege the service fee."
			);
			return;
		}
		const cartData = cartItems.map(({ item, quantity }) => ({
			skuNo: item.id,
			quantity,
		}));
		if (asyncStatus !== AsyncStatus.LOADING) {
			setAsyncStatus(AsyncStatus.LOADING);

			try {
				const result: {
					status: "OK" | "ERROR";
					msg: string;
					order: Stripe.orders.IOrder;
					errorType?: string;
					stripeData?: {
						message: string;
						type: string;
					};
				} = await authenticatedLambda("member-checkout", {
					cartData,
					paymentMethod: selSavedPaymentMethod.id,
				});
				if (result.status === "OK") {
					setAsyncStatus(AsyncStatus.LOADED);
					cart.reset();
					setStripeOrder(result.order);
				} else {
					setAsyncStatus(AsyncStatus.ERROR);
					if (result.stripeData && result.stripeData.message) {
						setDialogText(result.stripeData.message);
					}
				}
			} catch (error) {
				setAsyncStatus(AsyncStatus.ERROR);
			}
		}
	}
	return (
		<Card>
			<form onSubmit={e => onFormSubmit(e)}>
				<CardText>
					<>
						{selSavedPaymentMethod && (
							<SelectField
								id="saved-cc-select-dropdown"
								menuItems={savedCCSelections}
								label={getNick(selSavedPaymentMethod)}
								style={{ width: "100%" }}
								helpText="Saved Credit card to use for this payment"
							>
								<IconSeparator label="">
									<FontIcon>arrow_drop_down</FontIcon>
								</IconSeparator>
							</SelectField>
						)}
						<Elements>
							<SaveCreditCard>
								{selSavedPaymentMethod ? (
									<LinkButton>
										Save another Credit Card to your account
									</LinkButton>
								) : null}
							</SaveCreditCard>
						</Elements>
					</>
				</CardText>
				<CardText>
					<ServiceFee
						checked={serviceFeeAck}
						subtotal={cartItems.reduce<number>(
							(accum, { item, quantity }) => accum + item.price * quantity,
							0
						)}
						onChange={checked => setServiceFeeAck(checked)}
					/>
				</CardText>
				<CardActions>
					<Button
						raised
						primary
						type="submit"
						disabled={!formIsValid || isLoading}
					>
						{isLoading ? "Submitting...." : "Checkout"}
					</Button>
				</CardActions>
			</form>
		</Card>
	);
};
