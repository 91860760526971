import React, { FunctionComponent, ButtonHTMLAttributes } from "react";

// const styles = require("./linkButton.scss");
import styles from "./simpleButtons.module.scss";

export const LinkButton: FunctionComponent<
	ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className, ...props }) => (
	<button className={`${styles.linkButton} ${className}`} {...props} />
);
