import React, { FunctionComponent, useContext, useState } from "react";
import { IdentityUserContext } from "../Identity";
import { TextField, Button } from "react-md";
import { ToastContext } from "../../common/ToastProvider";
import { LinkButton } from "../../common/SimpleButtons";
import { useLoginModal } from "./LoginRegisterModal";

export const ForgotPassword: FunctionComponent<{ closeDialog: () => void }> = ({
	closeDialog,
}) => {
	const user = useContext(IdentityUserContext);
	const showLogin = useLoginModal();
	const toast = useContext(ToastContext);
	const [userEmail, setUserEmail] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const handleResetClick = () => {
		setSubmitting(true);
		user.userService.current
			.forgotPassword(userEmail)
			.then(() => {
				toast("Please check you email");
				setSubmitting(false);
				closeDialog();
			})
			.catch(() => {
				toast("u sure?");
				setSubmitting(false);
			});
	};
	return (
		<form onSubmit={e => e.preventDefault()}>
			<TextField
				id="login_user_email"
				type="email"
				name="email"
				value={userEmail}
				onChange={email => setUserEmail(email as string)}
				label="Email"
			/>
			<Button primary raised onClick={handleResetClick} disabled={submitting}>
				Recover my password
			</Button>
			<p>
				Don't have an account? You can{" "}
				<LinkButton onClick={() => showLogin("register")}>Sign up</LinkButton>
			</p>
		</form>
	);
};
