import React, { FunctionComponent, useContext, useState } from "react";
import { TextField, Button } from "react-md";
import { ToastContext } from "../../common/ToastProvider";
import { useLoginModal } from "./LoginRegisterModal";
import { LinkButton } from "../../common/SimpleButtons";
import { IdentityUserContext } from "../Identity";

export const Register: FunctionComponent<{ closeDialog: () => void }> = ({
	closeDialog,
}) => {
	const user = useContext(IdentityUserContext);
	const toast = useContext(ToastContext);
	const showLoginModal = useLoginModal();
	const [full_name, setUserName] = useState("");
	const [phone_number, setPhoneNumber] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [password, setPassword] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const handleRegisterClick = () => {
		setSubmitting(true);
		user.userService.current
			.register(userEmail, password, {
				full_name,
				phone_number,
			})
			.then(() => {
				toast("Successfuly created account.");
				setSubmitting(false);
				closeDialog();
			})
			.catch(() => {
				toast("Account creation failed");
				setSubmitting(false);
			});
	};
	return (
		<form onSubmit={e => e.preventDefault()}>
			<TextField
				id="register_user_name"
				type="text"
				name="name"
				value={full_name}
				onChange={name => setUserName(name as string)}
				label="Name"
			/>
			<TextField
				id="register_user_phone"
				type="text"
				name="phone"
				value={phone_number}
				onChange={phone => setPhoneNumber(phone as string)}
				label="Phone Number"
			/>
			<TextField
				id="login_user_email"
				type="email"
				name="email"
				value={userEmail}
				onChange={email => setUserEmail(email as string)}
				label="Email"
			/>
			<TextField
				id="register_user_password"
				type="password"
				name="password"
				value={password}
				onChange={password => setPassword(password as string)}
				label="Password"
			/>
			<Button
				primary
				raised
				onClick={handleRegisterClick}
				disabled={submitting}
			>
				{submitting ? "Submitting" : "Register"}
			</Button>
			<p>
				Allready have an account? you can{" "}
				<LinkButton onClick={() => showLoginModal("login")}>Log In</LinkButton>
			</p>
		</form>
	);
};
