import React, { Component } from "react";
import UserLinks from "../UserLinks";
import "./ToolbarActions.scss";
import { LoginButton } from "../LoginButton";

class Toolbar extends Component {
	render() {
		const { config } = this.props;
		return (
			<div className="toolbar-actions">
				<div className="userlinks-container">
					<UserLinks config={config} />
					<LoginButton />
				</div>
			</div>
		);
	}
}

export default Toolbar;
