export async function authenticatedFetch(
	token: string,
	path: string,
	payload?: any,
	method?: RequestInit["method"]
) {
	method = method || payload ? "POST" : "GET";
	return fetch(path, {
		method,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(payload),
	}).then(data => data.json());
}

export function authenticatedLambda(
	token: string,
	serviceName: string,
	payload?: any
) {
	return authenticatedFetch(
		token,
		`/.netlify/functions/${serviceName}`,
		payload
	);
}
