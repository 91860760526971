import React, { FunctionComponent, useContext, useState } from "react";
import { TextField, Button } from "react-md";
import { ToastContext } from "../../common/ToastProvider";
import { LinkButton } from "../../common/SimpleButtons";
import { useLoginModal } from "./LoginRegisterModal";
import { IdentityUserContext } from "../Identity";

export const Login: FunctionComponent<{ closeDialog: () => void }> = ({
	closeDialog,
}) => {
	const user = useContext(IdentityUserContext);
	const showLogin = useLoginModal();
	const toast = useContext(ToastContext);
	const [userEmail, setUserEmail] = useState("");
	const [password, setPassword] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const handleLoginClick = () => {
		setSubmitting(true);
		user.userService.current
			.login(userEmail, password)
			.then(() => {
				toast("Successfuly logged in.");
				setSubmitting(false);
				closeDialog();
			})
			.catch(() => {
				toast("Login failed");
				setSubmitting(false);
			});
	};
	return (
		<form onSubmit={e => e.preventDefault()}>
			<TextField
				id="login_user_email"
				type="email"
				name="email"
				value={userEmail}
				onChange={email => setUserEmail(email as string)}
				label="Email"
			/>
			<TextField
				id="login_user_password"
				type="password"
				name="password"
				value={password}
				onChange={password => setPassword(password as string)}
				label="Password"
			/>
			<Button primary raised onClick={handleLoginClick} disabled={submitting}>
				{submitting ? "Logging In" : "Login"}
			</Button>
			<p>
				Don't have an account? You can{" "}
				<LinkButton onClick={() => showLogin("register")}>Sign up</LinkButton>
			</p>
			<LinkButton onClick={() => showLogin("forgot")}>
				Forgot password
			</LinkButton>
		</form>
	);
};
